import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChildFn, CanActivateFn, RouterStateSnapshot } from '@angular/router';
import { PathAccessConfig, RouteActivationService } from './route-activation.service';


export function routeActivationGuard(path: string): CanActivateChildFn | CanActivateFn {
  return (route: ActivatedRouteSnapshot, _: RouterStateSnapshot) => {
    const config = pathAccessConfigs.get(path);
    return inject(RouteActivationService).hasPermission(route, config);
  }
}

// When adding a new activation guard, add it to the configs map.
const pathAccessConfigs: Map<string, PathAccessConfig> = new Map([
  ['edi', {
    loadRoutes: ['layout', 'int-edi'],
    loadWidgets: ['int-edi']
  }],
  ['feeschedules', {
    loadRoutes: ['layout', 'fee'],
    loadWidgets: ['fee']
  }],
  ['help-center', {
    loadRoutes: ['layout'],
    loadWidgets: ['layout']
  }],
  ['home', {
    loadRoutes: ['layout', 'orders', 'mcq'],
    loadWidgets: ['layout', 'orders', 'mcq']
  }],
  ['lms', {
    loadRoutes: ['layout', 'workforce'],
    loadWidgets: ['workforce']
  }],
  ['mcq', {
    loadRoutes: ['layout', 'mdm', 'payers', 'mcq'],
    loadWidgets: ['mdm', 'payers', 'users', 'mcq']
  }],
  ['mdm', {
    loadRoutes: ['layout', 'mdm'],
    loadWidgets: ['mdm']
  }],
  ['members', {
    loadRoutes: ['layout', 'mdm', 'members', 'orders'],
    loadWidgets: ['mdm', 'members', 'orders']
  }],
  ['orders', {
    loadRoutes: ['layout', 'mdm', 'payers', 'members', 'orders'],
    loadWidgets: ['mdm', 'payers', 'members', 'orders']
  }],
  ['payer-order-upload', {
    loadRoutes: ['layout', 'pay-order'],
    loadWidgets: ['pay-order']
  }],
  ['payers', {
    loadRoutes: ['layout', 'mdm', 'payers'],
    loadWidgets: ['mdm', 'payers', 'users']
  }],
  ['payments', {
    loadRoutes: ['layout', 'mdm', 'payment', 'int-payment'],
    loadWidgets: ['mdm', 'payment', 'int-payment']
  }],
  ['deposits', {
    loadRoutes: ['layout', 'mdm', 'deposit', 'payment'],
    loadWidgets: ['mdm', 'deposit']
  }],
  ['recon', {
    loadRoutes: ['layout', 'mdm', 'payment', 'recon'],
    loadWidgets: ['mdm', 'deposit', 'recon']
  }],
  ['providers', {
    loadRoutes: ['layout', 'mdm', 'provider'],
    loadWidgets: ['mdm', 'provider']
  }],
  ['reports', {
    loadRoutes: ['layout', 'sched'],
    loadWidgets: ['sched']
  }],
  ['sched', {
    loadRoutes: ['layout', 'sched'],
    loadWidgets: ['sched']
  }],
  ['int-fee', {
    loadRoutes: ['layout', 'int-fee'],
    loadWidgets: ['int-fee']
  }],
  ['disburse', {
    loadRoutes: ['layout', 'disburse'],
    loadWidgets: ['disburse']
  }]
]);
